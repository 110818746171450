import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  hasResume: false,
  user: {}
};

const slice = createSlice({
  name: 'authJwt',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getInitialize(state, action) {
      //console.log('hellloooooooooooooooooooo', action.payload);
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
      state.talentCard = action.payload.talentCard;
      state.hasResume = action.payload.hasResume;
    },

    // LOGIN
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.hasResume = action.payload.hasResume;
      state.talentCard = action.payload.talent;
      // state.talent = action.payload.talent
    },

    loginFailure(state, action) {
      state.isAuthenticated = false;
      state.user = null;
      state.error = action.payload.error
      // state.hasResume = action.payload.hasResume;
      // state.talent = action.payload.talent
    },

    // REGISTER
    registerSuccess(state, action) {
      // state.isAuthenticated = true;
      state.user = action.payload;
    },

    // LOGOUT
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    try {
      const tokenSize = new Blob([accessToken]).size;
      console.log("Token size in bytes:", tokenSize);

      // Attempt to store the token in localStorage
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } catch (error) {
      console.error("Error storing accessToken in localStorage:", error);
      throw new Error("Failed to store accessToken. It might exceed the quota.");
    }
  } else {
    // Clear the token
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};




// ----------------------------------------------------------------------

export function login({ email, password }) {
  return async (dispatch) => {
    try {
      // Make the login API request
      const response = await axios.post('/api/auth/login', {
        username: email,
        password
      });

      const { talent, hasResume, user, token, error } = response.data;

      if (error) {
        console.error("Login failed with error:", error);
        dispatch(slice.actions.loginFailure({ error }));
        return; // Exit the function after handling the error
      }

      // Set token in session (localStorage or sessionStorage)
      try {
        setSession(token); // Ensure this handles large token sizes properly
      } catch (storageError) {
        console.error("Error storing the token:", storageError);
        dispatch(slice.actions.loginFailure({ error: 'Failed to store the token.' }));
        return; // Exit the function after handling the storage issue
      }

      // Dispatch login success action
      dispatch(slice.actions.loginSuccess({ talent, hasResume, user }));

      // Optionally initialize user-specific data
      // await dispatch(getInitialize()); 
    } catch (err) {
      console.error("Error during login process:", err);
      dispatch(slice.actions.loginFailure({ error: err.message || "Login failed." }));
    }
  };
}


// ----------------------------------------------------------------------

export function register({
  email,
  password,
  firstName,
  lastName,
  contact,
  job_role
}) {
  return async (dispatch) => {
    const response = await axios.post('/api/auth/register', {
      username: email,
      password,
      firstName,
      lastName,
      contactNumber: contact,
      currentDesignation: job_role
    });
    const { accessToken, user } = response.data;

    // window.localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('authenticatedUser', JSON.stringify({ email: email, firstName: firstName }));
    dispatch(slice.actions.registerSuccess(response.data));
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async (dispatch) => {
    setSession(null);
    dispatch(slice.actions.logoutSuccess());
    window.localStorage.removeItem('accessToken');
  };
}

// ----------------------------------------------------------------------

export function getInitialize() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    //console.log("GET INITIALIZE");
    try {
      const accessToken = window.localStorage.getItem('accessToken');
     
      let talentId = jwtDecode(accessToken)._id;
      if (accessToken) {
        setSession(accessToken);

        const response = await axios.get(`api/talent/${talentId}`);
        //console.log('response is ', response);
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user: response.data.user,
            talentCard: response.data.talent,
            hasResume: response.data.hasResume
          })
        );
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: null
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null
        })
      );
    }
  };
}
